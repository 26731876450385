<template>
  <section
    class="sec--contact"
    :style="{ 'background-image': 'url(' + bgImg + ')' }"
  >
    <div class="cont--contact">
      <div class="wrap--header">CONTACT US</div>
      <div class="wrap--contact">
        <div class="wrap--left">
          <div
            class="cont--textbox"
            v-for="(item, index) in contactData"
            :key="index"
          >
            <div class="title">{{ item.title }}</div>
            <div class="desc" v-html="item.desc"></div>
          </div>
          <div class="cont--hours">
            <div class="title">Office Hours</div>
            <table
              class="hours"
              v-for="(item, index) in officeHours"
              :key="index"
              v-html="item"
            ></table>
          </div>
        </div>
        <div class="wrap--right">
          <div class="wrap--title">
            Your questions and comments are important to us! Please use the form
            below to send us an email, and note that the more information you
            provide, the better we can serve you.
          </div>
          <div class="cont--form" v-if="!sendEmailFlag">
            <ul>
              <li>
                <div
                  class="error--msg animated fadeIn"
                  v-if="pNameErrTxt != ''"
                >
                  {{ pNameErrTxt }}
                </div>
                <input
                  type="text"
                  :placeholder="pNameTxt"
                  v-model="name"
                  name="name"
                  id="name"
                />
              </li>
              <li>
                <div
                  class="error--msg animated fadeIn"
                  v-if="pEmailErrTxt != ''"
                >
                  {{ pEmailErrTxt }}
                </div>
                <input
                  type="text"
                  :placeholder="pEmailTxt"
                  v-model="email"
                  name="email"
                  id="email"
                />
              </li>
              <li>
                <div
                  class="error--msg animated fadeIn"
                  v-if="pPhoneErrTxt != ''"
                >
                  {{ pPhoneErrTxt }}
                </div>
                <input
                  type="text"
                  :placeholder="pPhoneTxt"
                  v-model="phone"
                  name="phone"
                  id="phone"
                />
              </li>
              <li>
                <div
                  class="error--msg animated fadeIn"
                  v-if="pCategoryErrTxt != ''"
                >
                  {{ pCategoryErrTxt }}
                </div>
                <select id="category" name="category" v-model="category">
                  <option value="0">Choose Category</option>
                  <option value="1">Pro Shop</option>
                  <option value="2">Custom Fitting</option>
                  <option value="3">Academy</option>
                </select>
              </li>
              <li>
                <div
                  class="error--msg animated fadeIn"
                  v-if="pQuestionErrTxt != ''"
                >
                  {{ pQuestionErrTxt }}
                </div>
                <textarea
                  id="question"
                  name="question"
                  cols="50"
                  rows="6"
                  :placeholder="pQuestionTxt"
                  v-model="message"
                ></textarea>
              </li>
              <li><input type="submit" value="Send" @click="checkForm" /></li>
            </ul>
          </div>
          <div class="cont--send" v-if="sendEmailFlag">
            Send your request ...
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import bgImg from "@/assets/contact/bg_contact.jpg";

export default {
  name: "ContactUs",
  data: () => {
    return {
      errors: [],
      name: null,
      email: null,
      phone: null,
      category: 0,
      message: null,
      pNameTxt: "Name *",
      pEmailTxt: "email@email.com *",
      pPhoneTxt: "Phone *",
      pQuestionTxt: "Question? (at least 3 word more)",
      pNameErrTxt: "",
      pEmailErrTxt: "",
      pPhoneErrTxt: "",
      pCategoryErrTxt: "",
      pQuestionErrTxt: "",
      bgImg: bgImg,
      sendEmailFlag: false,
      contactData: [
        {
          title: "Location",
          desc: `<ul class="ul--desc"><li>16651 Rannie Road Pitt Meadows,  BC,  V3Y 1Z1 CANADA</li></ul>`
        },
        {
          title: "Tel.",
          desc: `<ul class="ul--desc"><li><a href="tel:778-957-0722">778-957-0722</a></li></ul>`
        },
        {
          title: "E-mail",
          desc: `<ul class="ul--desc"><li><a href="mailto:info@thegolfmecca.com">info@thegolfmecca.com</a></li></ul>`
        }
      ],
      officeHours: [
        `<tr><td>Monday</td><td>10 am ~ 5 pm</td></tr>`,
        `<tr><td>Tuesday</td><td>10 am ~ 5 pm</td></tr>`,
        `<tr><td>Wednesday</td><td>10 am ~ 2 pm</td></tr>`,
        `<tr><td>Thursday</td><td>10 am ~ 5 pm</td></tr>`,
        `<tr><td>Friday</td><td>10 am ~ 5 pm</td></tr>`,
        `<tr><td>Saturday</td><td>10 am ~ 5 pm</td></tr>`,
        `<tr><td>Sunday</td><td>Close</td></tr>`
      ]
    };
  },
  methods: {
    checkForm: function(e) {
      let nameValue = false;
      let emailValue = false;
      let phoneValue = false;
      let categoryValue = false;
      let questionValue = false;

      if (!this.name) {
        this.pNameErrTxt = "At least 3 word more!";
        $("#name").css({ "border-color": "red" });
      } else if (this.name.length < 3) {
        this.pNameErrTxt = "At least 3 word more!";
        $("#name").css({ "border-color": "red" });
      } else {
        this.pNameErrTxt = "";
        $("#name").css({ "border-color": "black" });
        nameValue = true;
      }
      if (!this.email) {
        this.pEmailErrTxt = "At least 6 word more!";
        $("#email").css({ "border-color": "red" });
      } else if (this.email.length < 6) {
        this.pEmailErrTxt = "At least 6 word more!";
        $("#email").css({ "border-color": "red" });
      } else if (!this.validEmail(this.email)) {
        this.pEmailErrTxt = "Please type email correctly!";
        $("#email").css({ "border-color": "red" });
      } else {
        this.pEmailErrTxt = "";
        $("#email").css({ "border-color": "black" });
        emailValue = true;
      }
      if (!this.phone) {
        this.pPhoneErrTxt = "At least 10 digit more!";
        $("#phone").css({ "border-color": "red" });
      } else if (this.phone.length < 10) {
        this.pPhoneErrTxt = "At least 10 digit more!";
        $("#phone").css({ "border-color": "red" });
      } else if (!this.validPhone(this.phone)) {
        this.pPhoneErrTxt = "Please type number correctly!";
        $("#phone").css({ "border-color": "red" });
      } else {
        this.pPhoneErrTxt = "";
        $("#phone").css({ "border-color": "black" });
        phoneValue = true;
      }
      console.log(this.category);
      if (this.category == 0) {
        this.pCategoryErrTxt = "Select category correctly!";
        $("#category").css({ "border-color": "red" });
      } else {
        this.pCategoryErrTxt = "";
        $("#category").css({ "border-color": "black" });
        categoryValue = true;
      }
      if (!this.message) {
        this.pQuestionErrTxt = "At least 3 words";
        $("#question").css({ "border-color": "red" });
      } else if (this.message.length < 3) {
        this.pQuestionErrTxt = "At least 3 words";
        $("#question").css({ "border-color": "red" });
      } else {
        this.pCategoryErrTxt = "";
        $("#question").css({ "border-color": "black" });
        questionValue = true;
      }

      if (
        nameValue &&
        emailValue &&
        phoneValue &&
        categoryValue &&
        questionValue
      ) {
        this.sendEmailFlag = true;
        let categoryArr = ["", "Pro Shop", "Custom Fitting", "Academy"];
        let categoryTxt = categoryArr[this.category];
        this.sendEmail(
          this.name,
          this.email,
          this.phone,
          categoryTxt,
          this.message
        );
      } else {
        return;
      }
      e.preventDefault();
    },
    validEmail: email => {
      // eslint-disable-next-line no-useless-escape
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPhone: no => {
      // eslint-disable-next-line no-useless-escape
      var phoneno = /[0-9;+\-]/;
      return phoneno.test(no);
    },
    sendEmail: function(sndName, sndEmail, sndPhone, sndCategory, sndQuestion) {
      let vm = this;
      const baseURI = "https://api.smtp2go.com/v3/email/send";
      const sendData = {
        api_key: "api-A6F7912C30AA11EAA751F23C91C88F4E",
        sender: sndEmail,
        to: ["info@thegolfmecca.com"],
        subject: `[Question Email from Contact us page] I have a question that [${sndCategory}]`,
        html_body: `<h4 style="font-size:24px; margin-top:10px;margin-bottom:10px;">[Question Email from Contact us page]<br><br>${sndQuestion}<br><br></h4>
            <table>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #ccc; text-transform: uppercase; padding:5px; font-weight:bold;">Name :</td>
                <td style="width:200px; background-color: #ccc; padding:5px;">${sndName}</td>
              </tr>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #f4f4f4; text-transform: uppercase; padding:5px; font-weight:bold;">Email :</td>
                <td style="width:200px; background-color: #f4f4f4; padding:5px;">${sndEmail}</td>
              </tr>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #ccc; text-transform: uppercase; padding:5px; font-weight:bold;">Phone :</td>
                <td style="width:200px; background-color: #ccc; padding:5px;">${sndPhone}</td>
              </tr>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #f4f4f4; text-transform: uppercase; padding:5px; font-weight:bold;">Category :</td>
                <td style="width:200px; background-color: #f4f4f4; padding:5px;">${sndCategory}</td>
              </tr>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #ccc; text-transform: uppercase; padding:5px; font-weight:bold;">Message :</td>
                <td style="width:200px; background-color: #ccc; padding:5px;">${sndQuestion}</td>
              </tr>
            </table>
            <h6 style="font-size:18px;margin-top:20px;">We will reply to email or call you as soon. Thanks</h6>`
      };
      const headers = {
        "Content-Type": "application/json;charset=utf-8"
      };
      this.$http
        .post(baseURI, sendData, { headers })
        .then(result => {
          console.log(result);
          vm.sendEmailFlag = false;
          vm.name = "";
          vm.email = "";
          vm.phone = "";
          vm.category = 0;
          vm.message = "";
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
